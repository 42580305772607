<template>
  <div class="" style="margin-bottom: 50px">
    <div class="accordion" id="einzel_accordionExample">
      <div class="d-none d-sm-block">
        <div class="steps">
          <progress
            id="progress"
            :value="[step == 1 ? '50' : [step == 2 ? '75' : '100']]"
            max="100"
          ></progress>
          <div class="step-item">
            <button
              class="step-button text-center"
              type="button"
              aria-expanded="true"
            >
              1
            </button>
            <div class="step-title">scannen</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step > 1 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 1 ? 'true' : 'false']"
            >
              2
            </button>
            <div class="step-title">prüfen</div>
          </div>
        </div>
      </div>

      <div style="display: none">{{ scanncode }}</div>
      <div class="card1 m-1" v-if="step == '1'">
        <div class="card m-2" href="#" v-if="isLoading">
          <div class="card-body py-2">
            <a class="stretched-link"></a>
            <h4 class="card-text">
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> Laden...
            </h4>
          </div>
        </div>
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
          v-if="!isLoading"
        >
          <div
            class="alert alert-warning p-4 text-center my-0"
            v-if="geraet == ''"
          >
            <h2>
              Bitte zuerst in den Einstellungen (oben rechts) den Geräte-Namen
              setzen!
            </h2>
          </div>
          <div class="card-body" style="padding: 0" v-if="geraet != ''">
            <div class="card">
              <div class="card-header" v-if="!input">
                Scanne den Zugangscode
                <span v-if="geraet != ''">({{ geraet }})</span>...
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-around">
                  <div class="col-12 col-md-11">
                    <Form
                      @submit="submitData"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      v-if="!success"
                    >
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <font-awesome-icon
                            :icon="['fas', 'barcode']"
                            size="2xl"
                            v-if="!isLoading"
                          />

                          <font-awesome-icon
                            :icon="['fas', 'star-of-life']"
                            size="2xl"
                            spin
                            v-if="isLoading"
                          />
                        </span>
                        <Field
                          as="input"
                          name="barcode"
                          type="text"
                          class="form-control"
                          id="barcode"
                          ref="barcode"
                          autocomplete="given-name"
                          placeholder="QR-Code oder Zugangscode"
                          aria-label="QR-Code oder Zugangscode"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <small class="text-danger" v-if="errors.barcode">{{
                        errors.barcode
                      }}</small>

                      <div v-if="input != ''">
                        <div class="list-group"></div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <img
                    v-if="input == ''"
                    class="logo mt-5 ms-1"
                    src="@/assets/img/datasport_rot.svg"
                    style="width: 96%; max-width: 300px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="alert alert-danger text-center mt-3"
            v-if="personNichtGefunden"
          >
            <h2 class="py-4">
              Der Zugangscode wurde nicht gefunden oder ist abgelaufen!
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapGetters } from "vuex";
import Store from "@/store";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { uuid } from "vue-uuid";
import axios from "axios";
//import dayjs from "dayjs";
//import { useSound } from "@vueuse/sound";
//import soundError from "@/assets/sounds/error.mp3";
import { useToast, POSITION } from "vue-toastification";

import dayjs from "dayjs";

export default {
  name: "TheLogin",
  components: {
    Form,
    Field,
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  data() {
    const schema = yup.object().shape({
      barcode: yup
        .string()
        .required("Ein Barcode wird benötigt.")
        .trim()
        .min(30, "Der Barcode muss >30 Zeichen lang sein!"),
    });
    return {
      step: 1,
      schema,
      error: "",
      isLoading: false,
      success: false,
      personBereitsAusgegeben: false,
      personBereitsAusgegeben_Zeit: "",
      personNichtGefunden: false,
      startnummerNichtKorrekt: false,
      invitee: "",
      mode: "",
      input: "",
      suchenliste: [],
      persondaten: [],
      person_bib: "",
      person_bib_gescannt: "",
      person_t1_gescannt: "",
      aktuelles_team: "",
      uuid: "",
      geraet: "",
      //listeAbbrechen: false,

      toast: useToast(),
    };
  },

  computed: {
    isOnLine() {
      return Store.getters.activeOnline();
    },
    scanncode() {
      var scanncode_akt = Store.getters.activeBarcode();
      if (scanncode_akt.org) {
        console.log("LOGIN scanncode aufruf!", scanncode_akt);
        //this.zeigeFehlerStartnummer(false);
        if (this.step == 1) {
          if (scanncode_akt.type == "§") {
            // suche nach dem Registrierungscode im SEPAMandate Feld
            //this.holeTeamUCI(scanncode_akt.gsplit[1]);
            this.holeLogin(scanncode_akt.org);

            Store.mutations.setBarcode([]);
            scanncode_akt = [];
          } else {
            // Barcode falsch
            this.toast.error("Barcode an dieser Stelle nicht möglich!", {
              position: POSITION.TOP_CENTER,
            });
            Store.mutations.setBarcode([]);
            scanncode_akt = [];
          }
        }
      }
      return scanncode_akt;
    },
  },

  methods: {
    set_uuid(uuid) {
      this.uuid = uuid;
      localStorage.uuid = uuid;
    },
    setView(view) {
      Store.mutations.setActiveView(view);
      this.$emit("modus-updated", { newModus: "0" });
    },

    holeLogin(UuId) {
      if (this.geraet != "") {
        console.log("holeStatus", UuId, this.isOnLine);
        if (this.isOnLine) {
          this.isLoading = true;
          axios
            .get(
              "https://anmeldungsservice.pani-verwaltung.de/module/link_checkinapp.php" +
                "?link=" +
                UuId +
                "&geraet=" +
                this.geraet +
                "&uuid=" +
                uuid.v1() +
                {
                  headers: { "Content-Type": "application/json" },
                },
              {
                timeout: 5000,
              }
            )
            .then((response) => {
              console.log(response.data.status);

              if (response.data.status == "0") {
                console.log("UUID geholt!");

                //Store.mutations.setActiveView(response.data.activeView);

                this.set_uuid(response.data.linkname);

                let ok = 1;

                var now = dayjs().unix();
                if (response.data.von > now) {
                  // Event ist noch nicht aktiv
                  this.toast.warning("Event ist noch nicht aktiv -> Logout!", {
                    position: POSITION.TOP_CENTER,
                  });
                  ok = 0;
                  setTimeout(() => {
                    this.logoutButton();
                  }, 1500);
                }

                // prüfe ob der Zeitraum passt
                if (now > response.data.bis && ok == 1) {
                  // Event ist abgelaufen
                  this.toast.error("Event ist abgelaufen -> Logout!", {
                    position: POSITION.TOP_CENTER,
                  });
                  ok = 0;
                  setTimeout(() => {
                    this.logoutButton();
                  }, 1500);
                }

                if (ok == 1) {
                  localStorage.EventVon = response.data.von;
                  Store.mutations.setEventVon(response.data.von);
                  localStorage.EventBis = response.data.bis;
                  Store.mutations.setEventBis(response.data.bis);
                  localStorage.rrid = response.data.rrid;
                  Store.mutations.setRREvent(response.data.rrid);
                  localStorage.mode = response.data.mode;
                  Store.mutations.setMode(response.data.mode);
                  localStorage.eventname = response.data.eventname;
                  Store.mutations.setEventName(response.data.eventname);
                  localStorage.activeView = response.data.activeView;
                  this.toast.success("Login erfolgreich!");
                  this.setView(response.data.activeView);
                }
                this.isLoading = false;
              } else {
                console.log("UUID fehler!");
                // Fehlermeldung falsch
                this.toast.error(response.data.error_text, {
                  position: POSITION.TOP_CENTER,
                });
                this.isLoading = false;
              }
              return true;
            })
            .catch((error) => {
              this.isLoading = false;

              // versuche es mit einem Offline-Login
              //this.isOnLine = false;
              this.holeLogin(localStorage.uuid);
              //throw new Error(error);
              console.log(error.code);
              console.log(error.message);
              console.log(error.stack);
            });
        } else {
          // offline Login probieren
          let ok = 1;
          if (localStorage.EventVon) {
            console.log("EventVon exists in localstorage");
          } else {
            console.log("EventVon does not exist in localstoarge");
            ok = 0;
          }
          if (localStorage.EventBis) {
            console.log("EventBis exists in localstorage");
          } else {
            console.log("EventBis does not exist in localstoarge");
            ok = 0;
          }
          if (localStorage.rrid) {
            console.log("rrid exists in localstorage");
          } else {
            console.log("rrid does not exist in localstoarge");
            ok = 0;
          }
          if (localStorage.mode) {
            console.log("mode exists in localstorage");
          } else {
            console.log("mode does not exist in localstoarge");
            ok = 0;
          }
          if (localStorage.eventname) {
            console.log("eventname exists in localstorage");
          } else {
            console.log("eventname does not exist in localstoarge");
            ok = 0;
          }
          if (localStorage.activeView) {
            console.log("activeView exists in localstorage");
          } else {
            console.log("activeView does not exist in localstoarge");
            ok = 0;
          }

          if (ok == 0) {
            // Fehlermeldung
            this.toast.error(
              "Keine Daten auf Gerät gefunden, kein offline-Login möglich!",
              {
                position: POSITION.TOP_CENTER,
              }
            );
          } else {
            // prüfe ob der Zeitraum passt
            var now = dayjs().unix();
            if (localStorage.EventVon > now) {
              // Event ist noch nicht aktiv
              this.toast.warning("Event ist noch nicht aktiv -> Logout!", {
                position: POSITION.TOP_CENTER,
              });
              ok = 0;
              setTimeout(() => {
                this.logoutButton();
              }, 1500);
            }

            // prüfe ob der Zeitraum passt
            if (now > localStorage.EventBis && ok == 1) {
              // Event ist abgelaufen
              this.toast.error("Event ist abgelaufen -> Logout!", {
                position: POSITION.TOP_CENTER,
              });
              ok = 0;
              setTimeout(() => {
                this.logoutButton();
              }, 1500);
            }

            if (ok == 1) {
              Store.mutations.setEventVon(localStorage.EventVon);
              Store.mutations.setEventBis(localStorage.EventBis);
              Store.mutations.setRREvent(localStorage.rrid);
              Store.mutations.setMode(localStorage.mode);
              Store.mutations.setEventName(localStorage.eventname);
              this.toast.success("Offline-Login erfolgreich!");
              this.setView(localStorage.activeView);
            }
          }
        }
      } else {
        this.toast.error("Geräte-Name ist nicht gesetzt", {
          position: POSITION.TOP_CENTER,
        });
      }
    },

    logoutButton() {
      localStorage.uuid = "";
      localStorage.offlineCache = "";
      localStorage.EventVon = "";
      localStorage.EventBis = "";
      localStorage.rrid = "";
      localStorage.mode = "";
      localStorage.eventname = "";
      localStorage.activeView = "";
      location.reload(true);
    },

    submitData(values) {
      this.isLoading = true;
      this.success = false;
      this.error = "";
      console.log(values);
      this.input = values.barcode;
      //this.invitee = uuid.v1();
      window.scrollTo({
        top: 10,
        left: 0,
        behavior: "smooth",
      });
      this.holeLogin(values.barcode);
    },
    focusInput() {
      this.$refs.barcode.$el.focus();
    },
    modusUpdate(event) {
      console.log("modusUpdate", event);
      this.$emit("modus-updated", event);
      this.mode = event;
    },

    setBarcode(id, check) {
      if (check) {
        this.barcode = id;
        console.log("setBarcode ", this.barcode);
        this.holeTeam();
        console.log("setBarcode step = 2");
        this.step = 2;
      } else {
        console.log(check);
      }
    },
  },
  emits: {
    "modus-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Modus-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
  mounted() {
    if (localStorage.geraet) {
      this.geraet = localStorage.geraet;
      if (this.geraet.search("AUTO") == 0) {
        // Auto-1 nicht im Gerätenamen sondern bei RaR
        this.geraet = "";
        localStorage.geraet = "";
      }
      if (localStorage.uuid) {
        this.uuid = localStorage.uuid;
        if (this.uuid != "") {
          this.holeLogin(this.uuid);
        }
      } else {
        // Versuche den Parameter zu lesen
        let url = new URL(location.href);
        console.log("LINK: ", url, url.hash);
        if (url.hash) {
          this.holeLogin(url.hash.substring(1));
        }
      }
    }
  },
};
</script>

<style scoped>
.status_0-true,
.status_0-false {
  background: #fff;
  border: none;
}
.status_1-true {
  background: #d5ead5;
  border: none;
}
.status_1-false {
  background: #fbcc78;
  border: none;
}
.status_2-true,
.status_2-false {
  background: #58e791;
  border: none;
}

.bg-tagestransponder {
  background: #ffd501;
}
.text_gr {
  font-size: 1.1em;
}
.bg-success-bdr {
  background-color: #7099d1;
}
</style>
